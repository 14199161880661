export default (providedCode) => {
    const code = providedCode?.toUpperCase();
    switch (code) {
    case 'USD':
        return {
            symbol: '$',
            placement: 'left',
        }; // US Dollar
    case 'EUR':
        return {
            symbol: '€',
            placement: 'left',
        }; // Euro
    case 'CRC':
        return {
            symbol: '₡',
            placement: 'left',
        }; // Costa Rican Colón
    case 'GBP':
        return {
            symbol: '£',
            placement: 'left',
        }; // British Pound Sterling
    case 'ILS':
        return {
            symbol: '₪',
            placement: 'left',
        }; // Israeli New Sheqel
    case 'INR':
        return {
            symbol: '₹',
            placement: 'left',
        }; // Indian Rupee
    case 'JPY':
        return {
            symbol: '¥',
            placement: 'left',
        }; // Japanese Yen
    case 'KRW':
        return {
            symbol: '₩',
            placement: 'left',
        }; // South Korean Won
    case 'NGN':
        return {
            symbol: '₦',
            placement: 'left',
        }; // Nigerian Naira
    case 'PHP':
        return {
            symbol: '₱',
            placement: 'left',
        }; // Philippine Peso
    case 'PLN':
        return {
            symbol: 'zł',
            placement: 'left',
        }; // Polish Zloty
    case 'PYG':
        return {
            symbol: '₲',
            placement: 'left',
        }; // Paraguayan Guarani
    case 'THB':
        return {
            symbol: '฿',
            placement: 'left',
        }; // Thai Baht
    case 'UAH':
        return {
            symbol: '₴',
            placement: 'left',
        }; // Ukrainian Hryvnia
    case 'VND':
        return {
            symbol: '₫',
            placement: 'left',
        }; // Vietnamese Dong
    case 'SEK':
        return {
            symbol: ' kr',
            placement: 'right',
        }; // Swedish Krona
    default:
        return null;
    }
};
