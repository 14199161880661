module.exports = {
    name: 'Beyond',
    logo: 'https://img.genflow.com/beyond/checkout/logo.png',
    vendorUUID: 'c8ab735e-6ae3-40ee-bdf3-42e39abb6fb7',
    metaData: {
        title: 'Beyond',
        description: 'Take control of your fitness, diet and happiness with Beyond.',
        author: 'GENFLOW',
        url: 'https://www.beyondapp.co.uk/',
        image: 'https://img.genflow.com/beyond/checkout/logo.png',
    },
    theme: {
        colors: {
            primary: '#8dc8e8',
            text: '#697386',
            ctaText: '#fff',
        },
    },
    analytics: {
        gtag: '',
        facebookPixel: '463138434842238',
        tagManager: '',
        tapfiliate: '28362-e59a87',
        // segment: '', - Stored in env
    },
    links: {
        terms: 'https://beyondapp.co.uk/pages/terms-and-conditions',
        privacy: 'https://beyondapp.co.uk/policies/privacy-policy',
        support: 'https://support.beyondapp.co.uk/hc/en-us',
        genflow: 'https://genflow.com',
        ios: 'https://apps.apple.com/us/app/beyond-app/id1547481068',
        android: 'https://play.google.com/store/apps/details?id=com.meggangrubb.beyond',
    },
    forms: {
        errors: {
            existingUser: 'This user already exists, please login.',
            invalidPassword: 'The password needs to have 6 or more characters.',
            unknownError: 'The registration could not be processed.',
            userNotFound: 'Incorrect email/password combination',
        },
    },
    pages: {
        register: {
            title: 'Sign Up to',
            ctaText: 'Register',
            ctaLoadingText: 'Registering',
        },
        login: {
            title: 'Login to',
            ctaText: 'Login',
            ctaLoadingText: 'Logging In',

        },
    },
    products: [
        {
            title: '1 Month',
            // product: '1month',
            product: '1monthsale', // previous
            // label: '10% OFF',
            // coupon: 'tVswmJBp',
        }, {
            title: '3 Months',
            // product: '3monthsale',
            product: '3month', // previous
            // label: '20% OFF',
            // coupon: 'tukbgGEX',
        }, {
            title: '12 Months',
            // product: '12monthsale',
            product: '12month', // previous
            label: 'BEST VALUE',
            // coupon: '6B25dUOh',
        },
    ],
    saleProducts: [
        {
            title: '1 Month',
            product: '1monthsale',
            label: null,
        }, {
            title: '3 Months',
            product: '3monthsale',
            label: null,
        }, {
            title: '12 Months',
            product: '12monthsale',
            label: null,
        },
    ],
};
