import { navigate } from 'gatsby';
import React, { useEffect } from 'react';
import { Location } from '@reach/router';
import Cookies from 'js-cookie';
import { useSubscriptionAuth } from '../context/subscriptionAuthContext';
import { openStripeCheckout, redirectToCheckout } from '../utils/stripe';
import BRAND_CONFIG from '../BRAND_CONFIG';

const { products: availableProducts } = BRAND_CONFIG;

export const PRIVATE_ROUTES = [
    '/manage-subscription',
    '/subscribe',
    '/',
];

export const PUBLIC_ROUTES = [
    'thank-you',
    'forgot-my-password',
];

const cleanPathname = (pathname) => pathname?.replace(/\//g, '');

const setCookieForToday = (key, val) => {
    const date = new Date();
    date.setHours(24, 0, 0, 0);
    Cookies.set(key, val, {
        expires: date,
        path: '/',
    });
};

const NavigationHandler = ({ children, location }) => {
    const {
        update, loggedIn, product, subscribed, ready, code, currency, debug,
    } = useSubscriptionAuth();

    useEffect(() => {
        try {
            const codeCookie = Cookies.get('code');

            if (codeCookie) {
                if (codeCookie) {
                    update('code', codeCookie);
                }
            }

            // Add subscription type/product to context
            if (location.pathname.includes('subscribe')) {
                const newSubscriptionProduct = location.pathname.match(/subscribe\/(\w*)/g)?.[0]?.replace('subscribe', '').replace(/\//g, '');
                const newCode = location.pathname.match(/code\/(\w*)/g)?.[0]?.replace('code', '').replace(/\//g, '');
                if (newSubscriptionProduct && newSubscriptionProduct !== 'code') {
                    // If there's a new product, set it (ignore "code" as this
                    // is picked up when setting a code but no product)
                    update('product', newSubscriptionProduct);
                }
                if (newCode) {
                    update('code', newCode);
                    setCookieForToday('code', newCode);
                }
            }
            update('setup', true);
        } catch (error) {
            console.error(error);
        }
    }, []);

    // const findSessionIdByProduct = (productKey) => products[productKey]?.sessionId;

    useEffect(() => {
        (async () => {
            if (subscribed === null) return;
            const pathname = cleanPathname(location?.pathname);
            const isResetPassword = pathname.includes('reset-password');
            const isPublicRoute = PUBLIC_ROUTES.includes(pathname) || isResetPassword;
            const isLogin = pathname.includes('login');
            const isAdditionalProduct = !!product && !availableProducts.filter(
                ({ additional }) => !additional,
            ).map(
                ({ product: p }) => p,
            ).includes(product);

            // If product is in list, look for address: true to collect address, 
            // otherwise fallback to default additional checkoutSettings
            const collectAddress = isAdditionalProduct
                ? BRAND_CONFIG?.checkoutSettings?.additional?.address
                : !!availableProducts.find(({ product: p }) => p === product)?.collectAddress;
            // console.log('collectAddress', collectAddress, isAdditionalProduct, product, BRAND_CONFIG?.checkoutSettings?.additional);
            // Handle login navigation
            if (loggedIn && (!subscribed || isAdditionalProduct) && product && ready) {
                try {
                    // const sessionId = findSessionIdByProduct(product);
                    // openStripeCheckout(sessionId);
                    // Update: Use redirect with code to avoid race condition on direct links
                    redirectToCheckout(product, code, currency, null, collectAddress, debug);
                } catch (e) {
                    console.log(e);
                    navigate('/subscribe');
                }
            } else if (loggedIn && !subscribed && !isPublicRoute && !product) {
                navigate('/subscribe');
            } else if (loggedIn && subscribed && !isPublicRoute) {
                navigate('/manage-subscription');
            } else if (!loggedIn && !isPublicRoute && !isLogin) {
                navigate('/register');
            }
        })();
    }, [subscribed, loggedIn, ready]);

    return children;
};

const WrappedNavigationHandler = ({ children }) => (
    <Location>
        {({ location }) => <NavigationHandler location={location}>{children}</NavigationHandler> }
    </Location>
);

export default WrappedNavigationHandler;
