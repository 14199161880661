/* eslint-disable */
import axios from 'axios';
import BRAND_CONFIG from '../BRAND_CONFIG';

import {getToken, getUser} from './auth';

const stripeToken = process.env.GATSBY_STRIPE_KEY;
const BASE_URL = process.env.GATSBY_SUBSCRIPTION_ENV === 'dev' ? 'https://1osfn38ejh.execute-api.eu-west-1.amazonaws.com/dev' : 'https://ix6hdu8hu0.execute-api.eu-west-1.amazonaws.com/prod'
const SUBSCRIPTIONS_BASE_URL = `${BASE_URL}/subscriptions/${process.env.GATSBY_VENDOR_UUID}/stripe/`
const baseSessionUrl = `${SUBSCRIPTIONS_BASE_URL}session`;
const getSubscriptionsEndpoint = `${SUBSCRIPTIONS_BASE_URL}subscriptions`;
const cancelSubscriptionsEndpoint = `${SUBSCRIPTIONS_BASE_URL}subscriptions`;


const isBrowser = typeof window !== `undefined`;

export const getSession = (plan, IdToken, code, currency, coupon, collectAdress = false, debug) => {
    if (debug) {
        console.log('getsession: ', plan, IdToken, code, currency, coupon, debug);
    }
    let url = `${baseSessionUrl}/${plan}`;
    if (currency) {
        url = url + `/${currency}/`;
    }
    // Don't set price_type to prices if this is a one-off purchase
    url = url + '?price_type=prices';
    if (code) {
        url = url + `&promotion_code=${code}`
    } else if (coupon) {
        url = url + `&coupon=${coupon}`
    }
    if (collectAdress) {
        url = url + `&address=true`
    }
    if (debug) {
        url = url + `&debug=true`
    }
    if (debug) { console.log('getSession url: ', url); }
    return axios.get(url ,{
        timeout: 10000,
        headers: {'Authorization': `Bearer ${IdToken}`}
    }).catch(error => {
        if (debug) { console.log(error) }
        // The most common failure for session fetching is an invalid/unsuitable code, so retry without the code
        // TODO: Eventually move this logic to backend
        if (code) {
            if (debug) { console.log('fallbackURL; ', `${baseSessionUrl}/${plan}`) };
            url = currency ? `${baseSessionUrl}/${plan}/${currency}` : `${baseSessionUrl}/${plan}`;
            url = url + '?price_type=prices';
            if (debug) { 
                url = url + `&debug=true`;
                console.log('fallbackURL; ', url) 
            };
            return axios.get(url ,{
                timeout: 10000,
                headers: {'Authorization': `Bearer ${IdToken}`}
            }).catch(error => {
                console.error(error);
                return null;
            })
        }
    });
}

export const getSessionInfo = async (sessionId) => {
    const url = `${baseSessionUrl}/info/${sessionId}`
    const IdToken = await getToken();

    return axios.get(url ,{
        timeout: 10000,
        headers: {'Authorization': `Bearer ${IdToken.idToken.jwtToken}`}
    })
}

const SEGMENT_FACEBOOK_EVENT_MAP = {
    'Subscription Package Selected': 'InitiateCheckout',
}

const track = async (key, params) => {
        try {
            // TODO: Move elsewhere
            await window?.analytics?.track(key, params);
            if (window.fbq && !window.analytics) {
                window.fbq('track', SEGMENT_FACEBOOK_EVENT_MAP[key], params);
            }
        } catch (error) {
            console.error(error);
        }
};

const tapLead = async () => {
    if (isBrowser) {
        // Send Tapfiliate lead
        try {
            if (tap) {
                const user = await getUser();
                const userName = user.username;
                tap('lead', userName);
            }
        } catch(e){
            console.log(e);
        }
    }
}

// Used for direct links only
export const redirectToCheckout = async (uri, customCode, currency, coupon, collectAddress, debug) => {
    if(isBrowser){
        // Logic removed here
        // If: a custom code has been passed, use that (personalised sales/direct links)
        // Else: Check if a default code is set for this product, used for general sales
        // const code = customCode || BRAND_CONFIG.products?.find(x => x?.product === uri)?.code;
        try{
            const IdToken = await getToken();
            const data = await getSession(uri, IdToken.idToken.jwtToken, customCode, currency, coupon, collectAddress, debug);
            track('Subscription Package Selected', {id: uri})
            // await tapLead();
            console.log('data: ', data)
            await window.Stripe(stripeToken).redirectToCheckout({sessionId: data.data.sessionId});
        }catch(e){
            console.log(e);
        }
    }
};

export const openStripeCheckout = async (sessionId, id) => {
    track('Subscription Package Selected', {id});
    await tapLead();
    return window.Stripe(stripeToken).redirectToCheckout({sessionId: sessionId})
};

export const getSubscription = async () =>{

    const IdToken = await getToken();

    console.log(getSubscriptionsEndpoint);

    return axios.get(getSubscriptionsEndpoint,{
        timeout: 10000,
        headers: {'Authorization': `Bearer ${IdToken.idToken.jwtToken}`}
    });
};

export const cancelSubscription = async (subscriptionId) => {
    const IdToken = await getToken();

    return axios.get(`${cancelSubscriptionsEndpoint}/${subscriptionId}/cancel`,{
        timeout: 10000,
        headers: {'Authorization': `Bearer ${IdToken.idToken.jwtToken}`}
    });
};
