module.exports = {
    name: 'Level Up with Nona',
    logo: 'https://img.genflow.com/level-up/checkout/logo.png',
    vendorUUID: 'f3f7c717-e336-4874-9f05-c83c337c56a3',
    metaData: {
        title: 'Level Up with Nona',
        description: 'Step into the new you',
        author: 'GENFLOW',
        url: 'https://levelupwithnona.com/',
        image: 'https://img.genflow.com/level-up/checkout/logo.png',
    },
    theme: {
        colors: {
            primary: '#000',
            text: '#697386',
            ctaText: '#EEFF00',
        },
    },
    analytics: {
        gtag: '',
        facebookPixel: '',
        tagManager: '',
        tapfiliate: '',
        // segment: '', - Stored in env
    },
    links: {
        terms: 'https://www.levelupwithnona.com/pages/terms-and-conditions',
        privacy: 'https://www.levelupwithnona.com/pages/privacy-policy',
        support: 'https://levelupwithnona.zendesk.com/hc/en-gb',
        genflow: 'https://genflow.com',
        ios: 'https://apps.apple.com/us/app/level-up-with-nona/id6443918364',
        // android: 'https://play.google.com/store/apps/details?id=com.ownu.app',
    },
    forms: {
        errors: {
            existingUser: 'This user already exists, please login.',
            invalidPassword: 'The password needs to have 6 or more characters.',
            unknownError: 'The registration could not be processed.',
            userNotFound: 'Incorrect email/password combination',
        },
    },
    pages: {
        register: {
            title: 'Sign Up to',
            ctaText: 'Register',
            ctaLoadingText: 'Registering',
        },
        login: {
            title: 'Login to',
            ctaText: 'Login',
            ctaLoadingText: 'Logging In',

        },
    },
    products: [
        {
            title: '1 Month',
            product: '1month',
            // coupon: 'mwZmNnxZ',
            // label: '30% OFF',
        }, {
            title: '6 Months',
            product: '6month',
            // coupon: 'JSwqJQ9y',
            // label: '35% OFF',
        }, {
            title: '12 Months',
            product: '12month',
            // coupon: 'IKVNzSoY',
            // label: '40% OFF',
        },
    ],
    saleProducts: [
    ],
    // currencies: {
    //     available: [
    //         {
    //             code: 'GBP',
    //             icon: '🇬🇧',
    //         },
    //         {
    //             code: 'SEK',
    //             icon: '🇸🇪',
    //         },
    //         {
    //             code: 'EUR',
    //             icon: '🇪🇺',
    //         },
    //         {
    //             code: 'USD',
    //             icon: '🇺🇸',
    //         },
    //     ],
    //     default: 'EUR',
    // },
};
