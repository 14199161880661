module.exports = {
    name: 'Naturally Stefanie',
    logo: 'https://img.genflow.com/naturally-stefanie/checkout/logo.png',
    vendorUUID: 'bf1a0653-a95d-4e4a-9b0f-3950b3a63224',
    metaData: {
        title: 'Naturally Stefanie',
        description: 'Get stronger with Naturally Stefanie',
        author: 'GENFLOW',
        url: 'https://www.naturallystefanie.com/',
        image: 'https://img.genflow.com/naturally-stefanie/checkout/logo.png',
    },
    theme: {
        colors: { // Note these need to be copied into $theme-colors in src/styles/global.scss
            primary: '#A4CCB6',
            text: '#697386',
            ctaText: '#fff',
        },
    },
    analytics: {
        gtag: '',
        facebookPixel: '',
        tagManager: '',
        tapfiliate: '',
        // segment: '', - Stored in env
    },
    links: {
        terms: 'https://www.naturallystefanie.com/pages/terms-and-conditions',
        privacy: 'https://www.naturallystefanie.com/pages/privacy-policy',
        support: 'https://naturallystefanie.zendesk.com/hc/en-gb',
        genflow: 'https://genflow.com',
        // ios: 'https://apps.apple.com/lt/app/ownu/id1535357952',
        // android: 'https://play.google.com/store/apps/details?id=com.ownu.app',
    },
    forms: {
        errors: {
            existingUser: 'This user already exists, please login.',
            invalidPassword: 'The password needs to have 6 or more characters.',
            unknownError: 'The registration could not be processed.',
            userNotFound: 'Incorrect email/password combination',
        },
    },
    pages: {
        register: {
            title: 'Sign Up to',
            ctaText: 'Register',
            ctaLoadingText: 'Registering',
        },
        login: {
            title: 'Login to',
            ctaText: 'Login',
            ctaLoadingText: 'Logging In',

        },
    },
    products: [
        {
            title: '1 Month',
            product: '1month',
            label: null,
            // coupon: '3YdZxKmm',
        }, {
            title: '3 Months',
            product: '3month',
            label: null,
            // coupon: 'ciPbmdcL',
        }, {
            title: '12 Months',
            product: '12month',
            // coupon: 'DvdwDWbu',
            // label: 'free Band',
        },
    ],
    saleProducts: [
    ],
    // currencies: {
    //     available: [
    //         {
    //             code: 'GBP',
    //             icon: '🇬🇧',
    //         },
    //         {
    //             code: 'SEK',
    //             icon: '🇸🇪',
    //         },
    //         {
    //             code: 'EUR',
    //             icon: '🇪🇺',
    //         },
    //         {
    //             code: 'USD',
    //             icon: '🇺🇸',
    //         },
    //     ],
    //     default: 'EUR',
    // },
};
